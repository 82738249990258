<template>
  <section
    :key="`${uniqueKey}-${sectionData.sys.id}`"
    class="flexible-section"
    :class="`flexible--${componentName}`"
  >
    <component
      :is="componentName"
      :section-data="adaptedData"
      :unique-key="uniqueKey"
    />
  </section>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { adaptEditoProductPush } from 'templates/sections/Flexible/adapters/adaptEditoProductPush';

export default defineComponent({
  name: 'FlexibleSection',
  components: {
    InformationPleatsSection: () =>
      import('templates/sections/InformationPleatsSection/index.vue'),
    SubscriptionSection: () =>
      import('templates/sections/Subscription/index.vue'),
    CommitmentPush2: () =>
      import('templates/sections/CommitmentPush2/index.vue'),
    LegalPush: () => import('templates/sections/LegalPush/index.vue'),
    ProductBanner2: () => import('templates/sections/ProductBanner2/index.vue'),
    IllustratedPush: () =>
      import('templates/sections/IllustratedPush/index.vue'),
    BrandServices: () => import('templates/sections/BrandServices/index.vue'),
    LoyaltyPush: () => import('templates/sections/LoyaltyPush/index.vue'),
    ButtonsSection: () => import('templates/sections/ButtonsSection/index.vue'),
    ArticlePush: () => import('templates/sections/ArticlePush/index.vue'),
    InPageNav: () => import('templates/sections/InPageNav/index.vue'),
    ParagraphFlexible: () =>
      import('templates/sections/ParagraphFlexible/index.vue'),
    Callout: () => import('templates/sections/Callout/index.vue'),
    Divider: () => import('templates/sections/Divider/index.vue'),
    Quote: () => import('templates/sections/Quote/index.vue'),
    KeyFigures: () => import('templates/sections/KeyFigures/index.vue'),
    BrandStoryPush: () => import('templates/sections/BrandStoryPush/index.vue'),
    BoxSlider: () => import('templates/sections/BundleProducts/BoxSlider.vue'),
    BoxComposition: () =>
      import('templates/sections/BundleProducts/BoxComposition.vue'),
    ProductCarousel: () =>
      import('templates/sections/ProductCarousel/index.vue'),
    TableSection: () => import('templates/sections/TableSection/index.vue'),
    ContactPush: () => import('templates/sections/ContactPush/index.vue'),
    CountdownPush: () => import('templates/sections/CountdownPush/index.vue'),
    CollectionPush: () => import('templates/sections/CollectionPush/index.vue'),
    EditoProductPush: () =>
      import('templates/sections/EditoProductPush/index.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const componentName = computed(() => {
      const components = {
        'Information pleats panel': 'InformationPleatsSection',
        'Commitment Push': 'CommitmentPush2',
        'Product Banner': 'ProductBanner2',
        'Legal Push': 'LegalPush',
        Subscription: 'SubscriptionSection',
        'Illustrated Push': 'IllustratedPush',
        'Brand Services': 'BrandServices',
        'Brand Services - Option 1 (3:4)': 'BrandServices',
        'Brand Services - Option 2 (4:3)': 'BrandServices',
        'Loyalty push - With icons': 'LoyaltyPush',
        'Loyalty push - Banner/Text': 'LoyaltyPush',
        Buttons: 'ButtonsSection',
        'Buttons Compact': 'ButtonsSection',
        'In Page Nav - Anchors': 'InPageNav',
        'In Page Nav - Tabs': 'InPageNav',
        'Article Push - Major': 'ArticlePush',
        'Article Push - Standard': 'ArticlePush',
        Paragraph: 'ParagraphFlexible',
        Callout: 'Callout',
        'Divider - Thin Black': 'Divider',
        'Divider - Thin Light': 'Divider',
        'Divider - Thin Medium': 'Divider',
        'Divider - Thick Black': 'Divider',
        'Divider - Thick Light': 'Divider',
        'Divider - Thick Medium': 'Divider',
        'Quote - Left Text': 'Quote',
        'Quote - Centered Text': 'Quote',
        'Key Figures - Left': 'KeyFigures',
        'Key Figures - Right': 'KeyFigures',
        'Brand Story Push': 'BrandStoryPush',
        'Set to compose - Gift set in Page': 'BoxSlider',
        'Set to compose - Gift set Composition': 'BoxComposition',
        'Product Carousel': 'ProductCarousel',
        'Table - Highlighted Column': 'TableSection',
        'Table - Highlighted Row': 'TableSection',
        'Table - Simple': 'TableSection',
        'Contact Push': 'ContactPush',
        'Countdown Push': 'CountdownPush',
        'Edito Product Push': 'EditoProductPush',
        'Collection Push': 'CollectionPush'
      };

      const type = props.sectionData?.type;
      return type && components[type];
    });

    const adaptedData = computed(() => {
      const adaptersMap = {
        EditoProductPush: adaptEditoProductPush
      };

      const adapter = adaptersMap[componentName.value];
      return adapter ? adapter(props.sectionData) : props.sectionData;
    });

    return {
      componentName,
      adaptedData
    };
  }
});
</script>

<style scoped lang="scss">
.flexible-section.module {
  @apply z-base;
}

.flexible--InformationPleatsSection:first-child {
  @apply mt-md;
}
</style>
